import { UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import {
  ClusterizationTasksResponse,
  ClusterizationTaskId,
  ClusterizationTaskBrief,
  ClusterizationTaskSort,
  ClusterizationTaskCreateData,
} from '@playq/services-beetle';
import { Filter, Environment } from '@playq/octopus-common';

import { useEitherQuery, useMutateQueryDataAfterEntityDeleting } from '/api/service-hooks';
import { services2 } from '/api';
import { snackbarService } from '/common/snackbarService';

const taskKeys: unknown[] = ['tasks', 'query'];
// const clusterSortBy = [new ClusterizationTaskSort({ ord: SortDirection.None, field: ClusterizationTaskSortField.Id })];
// : ClusterizationTaskSort[]
export const useQueryTasks = (
  env: Environment,
  iterator: OffsetLimit,
  sortBy: ClusterizationTaskSort[],
  filterBy: {
    [key: string]: Filter;
  },
  options?: UseQueryOptions<ClusterizationTasksResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, refetch, ...res } = useEitherQuery(
    taskKeys.concat(env, sortBy, iterator, filterBy),
    () => {
      return services2.audienceRequestsClusterizationService.queryTasks(iterator, sortBy, filterBy, env);
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
  const queryClient = useQueryClient();
  const createTaskCache = ({ id, task }: { id: ClusterizationTaskId; task: ClusterizationTaskCreateData }) => {
    mutate((prev) => {
      if (!prev) {
        return;
      }

      const newResponse = new ClusterizationTasksResponse(prev.serialize());
      const clusterTaskBriefData = task.toClusterizationTaskBriefDataSerialized();
      const clusterTaskBrief = new ClusterizationTaskBrief({
        ...clusterTaskBriefData,
        id: id.serialize(),
        latestRun: undefined,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });

      newResponse.requests = [clusterTaskBrief, ...prev.requests];
      newResponse.total = prev.total + 1;

      return newResponse;
    });
  };

  const tasks = useMemo(() => res.data?.requests || [], [res.data?.requests]);
  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);
  const mutateDeletedIssue = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    // nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (task?: ClusterizationTaskBrief) => task?.id,
    getEntities: (task: ClusterizationTasksResponse) => task.requests,
    setEntities: (task: ClusterizationTasksResponse, newEntities: ClusterizationTaskBrief[]) => {
      task.requests = newEntities;
    },
    createNewResponse: (prevResponse: ClusterizationTasksResponse) =>
      new ClusterizationTasksResponse(prevResponse.serialize()),
  });
  return { ...res, tasks, total, createTaskCache, mutateDeletedIssue, refetch };
};
