import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { Credentials, CredentialsID } from '@playq/octopus2-auth';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { retrieveCredentialsKeys } from './constants';

export const useRetrieveCredentials = (
  id: CredentialsID | undefined,
  options?: UseQueryOptions<Credentials | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => retrieveCredentialsKeys.concat(id), [id]);

  return useEitherQuery(
    keys,
    () => {
      return services2.adminCredentialsManagementService.retrieve(id as CredentialsID);
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        const errorToDisplay = err?.message ? err : new Error('Could not retrieve Credentials');
        snackbarService.genericFailure(errorToDisplay);
      },
      ...options,
      enabled: (options?.enabled ?? true) && id !== undefined,
    }
  );
};
