import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { SupportRequestId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type DeleteSupportRequestPayloadType = {
  id: SupportRequestId;
  env: Environment;
};

export const deleteSupportRequestMutationKey: MutationKey = ['supportRequest', 'mutation', 'delete'];

export const useDeleteSupportRequest = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, DeleteSupportRequestPayloadType>
) =>
  useEitherMutation(({ id, env }) => services2.audienceSupportService.deleteSupportRequest(id, env), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: deleteSupportRequestMutationKey,
    ...options,
  });
