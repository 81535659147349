import { UploadBatchResponse } from '@playq/octopus2-files';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { onResponseError } from '../helpers';
import { useEitherMutationScripted } from '../scriptsService';
import { FILES_BATCH_UPLOAD_HOOK_NAME } from '../filesService/constants';

import { assetsService, assetsServiceName, uploadBatchAssetMutationKey } from './constants';
import { IUploadAssetsBatchParams, UseAssetsUploadBatchOptions } from './types';

export const useAssetsUploadBatchScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseAssetsUploadBatchOptions
) =>
  useEitherMutationScripted<IUploadAssetsBatchParams, UploadBatchResponse, IInvokeScriptsHook>({
    serviceMethod: assetsService.uploadBatch.bind(assetsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: assetsServiceName,
      mutationType: FILES_BATCH_UPLOAD_HOOK_NAME,
    },
    options: {
      onError: onResponseError,
      mutationKey: uploadBatchAssetMutationKey,
      ...options,
    },
  });
