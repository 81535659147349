import { QueryKey, MutationKey } from '@tanstack/react-query';

import { services2 } from '/api/services2';

export const assetsService = services2.appAssetsService;
export const assetsServiceName = assetsService.getClassName();

export const assetGetKeys: QueryKey = ['asset', 'get'];
export const assetGetDownloadUrlKeys: QueryKey = ['asset', 'downloadUrl'];
export const assetsPolicy: QueryKey = ['assets', 'policy'];
export const assetsQueryKeys: QueryKey = ['assets', 'query'];

export const createAssetMutationKey: MutationKey = ['asset', 'mutation', 'create'];
export const updateAssetMutationKey: MutationKey = ['asset', 'mutation', 'update'];
export const removeAssetMutationKey: MutationKey = ['asset', 'mutation', 'remove'];
export const updateAssetRevisionMutationKey: MutationKey = ['asset', 'mutation', 'updateRevision'];
export const uploadAssetMutationKey: MutationKey = ['asset', 'mutation', 'uploadFileEntity'];
export const uploadRetryAssetMutationKey: MutationKey = ['asset', 'mutation', 'uploadRetry'];
export const uploadBatchAssetMutationKey: MutationKey = ['asset', 'mutation', 'uploadBatch'];
