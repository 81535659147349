import {
  Traits,
  TraitGroup,
  TraitEntry,
  TraitEntryDateTime,
  TraitEntryString,
  TraitEntryInt,
} from '@playq/octopus2-analytics';

const startsAt = new TraitEntryDateTime();
startsAt.name = 'startsAt';
startsAt.desc = 'Actual recurrence start, not the general field';

const endsAt = new TraitEntryDateTime();
endsAt.name = 'endsAt';
endsAt.desc = 'Actual recurrence end, not the general field';

const name = new TraitEntryString();
name.name = 'name';
name.desc = 'Game event name';

const recurrence = new TraitEntryInt();
recurrence.name = 'recurrence';
recurrence.desc = 'index from 0 to X, whenever this is evaluated';

const commonHardcodedEntries: TraitEntry[] = [startsAt, endsAt, name];

const gameEventHardcodedGroup = new TraitGroup();
gameEventHardcodedGroup.name = '$self';
gameEventHardcodedGroup.entries = commonHardcodedEntries.concat(recurrence);

const experimentHardcodedGroup = new TraitGroup();
experimentHardcodedGroup.name = '$self';
experimentHardcodedGroup.entries = commonHardcodedEntries;

export const hardcodedGameEventTraits = new Traits();
hardcodedGameEventTraits.groups = [gameEventHardcodedGroup];

export const hardcodedExperimentTraits = new Traits();
hardcodedExperimentTraits.groups = [experimentHardcodedGroup];
