import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { ClusterizationTask, ClusterizationTaskId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const retrieveTaskKeys = ['retrieve', 'task'];
export const useRetrieveTask = (
  id: ClusterizationTaskId,
  env: Environment = Environment.Live,
  options?: UseQueryOptions<ClusterizationTask | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => retrieveTaskKeys.concat(id?.serialize(), env), [env, id]);
  const res = useEitherQuery(keys, () => services2.audienceRequestsClusterizationService.retrieveTask(id, env), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });

  const isLoading = res.isLoading && res.fetchStatus !== 'idle';
  return { ...res, isLoading };
};
