import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { IAppSaveOptionsVariables, ISaveAppParams } from './types';
import { appsService, appsServiceName } from './constants';

export const useAppSaveScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, IAppSaveOptionsVariables>
) =>
  useEitherMutationScripted<ISaveAppParams, CommonSuccess, IInvokeScriptsHook>({
    serviceMethod: appsService.saveTheApp.bind(appsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appsServiceName,
      mutationType: 'app save',
    },
    options: {
      onError: onResponseError,
      ...options,
    },
  });
