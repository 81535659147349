import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { FileResponse } from '@playq/octopus2-files';

import { UseMutationOptionsExtended } from '/api/service-hooks';
import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';
import { FILE_SAVE_HOOK_NAME } from '../filesService';

import { IAssetUpdateOptionsVariables, IUpdateAssetParams } from './types';
import { assetsService, assetsServiceName, updateAssetMutationKey } from './constants';

export const useAssetUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    IAssetUpdateOptionsVariables,
    FileResponse | undefined
  >
) =>
  useEitherMutationScripted<IUpdateAssetParams, CommonSuccess, IInvokeScriptsHook, FileResponse | undefined>({
    serviceMethod: assetsService.update.bind(assetsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: assetsServiceName,
      mutationType: FILE_SAVE_HOOK_NAME,
    },
    options: {
      onError: onResponseError,
      mutationKey: updateAssetMutationKey,
      ...options,
    },
  });
