import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppEntityContentResponse, AppEntityVersion } from '@playq/octopus2-apps';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { traitsService, traitsServiceName } from './constants';
import { IUpsertTraitsParams, ITraitsUpsertOptionsVariables } from './types';

export const useAppTraitsUpsertScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptions<
    AppEntityVersion,
    GenericFailure | Error,
    ITraitsUpsertOptionsVariables,
    AppEntityContentResponse | undefined
  >
) =>
  useEitherMutationScripted<
    IUpsertTraitsParams,
    AppEntityVersion,
    IInvokeScriptsHook,
    AppEntityContentResponse | undefined
  >({
    serviceMethod: traitsService.upsert.bind(traitsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: traitsServiceName,
      mutationType: 'traits save',
    },
    options: {
      onError: onResponseError,
      ...options,
    },
  });
