import { useCallback, useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import _ from 'lodash';

import { GenericFailure } from '@playq/services-shared';
import { AppID } from '@playq/octopus-common';
import { FileID, FileResponse, FileFeature } from '@playq/octopus2-files';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { assetGetKeys } from './constants';

export const useAssetGet = (
  appID?: AppID,
  id?: FileID,
  features: FileFeature[] = [],
  options?: UseQueryOptions<FileResponse | undefined, GenericFailure | Error>
) => {
  const enabled = useMemo(() => {
    const isIdValid = !_.isNil(id) && !_.isNil(appID);
    return isIdValid && (options?.enabled ?? true);
  }, [appID, id, options?.enabled]);

  const keys = useMemo(() => assetGetKeys.concat(id, appID), [id, appID]);

  const { mutate, ...res } = useEitherQuery(
    keys,
    () => services2.appAssetsService.getFile(appID as AppID, id as FileID, features),
    { ...options, enabled }
  );

  const mutateUpdatedAsset = useCallback(
    (asset: FileResponse) => {
      if (res.data) {
        mutate(asset);
      }
    },
    [mutate, res.data]
  );

  return {
    fileResponse: res.data,
    ...res,
    tags: keys,
    mutate,
    mutateUpdatedAsset,
  };
};
