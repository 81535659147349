import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { IssueId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type DeleteIssueBatchPayloadType = {
  ids: IssueId[];
  env: Environment;
};

export const deleteIssueBatchMutationKey: MutationKey = ['issue', 'muatation', 'delete', 'batch'];

export const useDeleteIssueBatch = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, DeleteIssueBatchPayloadType>
) =>
  useEitherMutation(({ ids, env }) => services2.audienceSupportService.deleteIssueBatch(ids, env), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: deleteIssueBatchMutationKey,
    ...options,
  });
