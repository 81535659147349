import { GenericFailure } from '@playq/services-shared';
import { PortalAppPackage } from '@playq/octopus2-economy';

import { snackbarService } from '/common/snackbarService';
import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';

import { IAppsPackageCreateOptionsVariables, ICreatePackageParams } from './types';
import { appsPackagesService, appsPackagesServiceName, createPackageMutationKey } from './constants';

export const useAppsPackageCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<PortalAppPackage, GenericFailure | Error, IAppsPackageCreateOptionsVariables>
) =>
  useEitherMutationScripted<ICreatePackageParams, PortalAppPackage, IInvokeScriptsHook>({
    serviceMethod: appsPackagesService.createPackage.bind(appsPackagesService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appsPackagesServiceName,
      mutationType: 'package creation',
    },
    options: {
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: createPackageMutationKey,
      ...options,
    },
  });
