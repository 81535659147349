import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { ClusterizationRunId, ClusterizationReport } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { useEitherQuery } from '/api/service-hooks';
import { services2 } from '/api';
import { snackbarService } from '/common/snackbarService';

const reportKeys: unknown[] = ['report', 'query'];
export const useQueryReport = (
  runId: ClusterizationRunId,
  iterator: OffsetLimit,
  env: Environment,
  minSize = 2,
  maxSize = 20,
  limitClusterDocuments = 20,
  options?: UseQueryOptions<ClusterizationReport | undefined, GenericFailure | Error>
) => {
  const { mutate, refetch, ...res } = useEitherQuery(
    reportKeys.concat(runId, env, minSize, iterator, maxSize, limitClusterDocuments),
    () => {
      return services2.audienceRequestsClusterizationService.queryReport(
        runId,
        minSize,
        maxSize,
        limitClusterDocuments,
        iterator,
        env
      );
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  return { ...res };
};
