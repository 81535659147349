import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { AppEntityContentResponse } from '@playq/octopus2-apps';
import { GenericFailure } from '@playq/services-shared';
import { TraitGroup, Traits } from '@playq/octopus2-analytics';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';

import { hardcodedGameEventTraits, hardcodedExperimentTraits } from './hardcodedTraits';
import { appTraitsRetrieveKeys, useRetrieveSystemTraits } from './useRetrieveSystemTraits';

const emptyArray: [] = [];

const hardcodedTraits = { gameEvents: hardcodedGameEventTraits, experiments: hardcodedExperimentTraits };

export const useAppTraitsRetrieve = (
  version?: number,
  options?: UseQueryOptions<AppEntityContentResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const { groups: systemTraitsGroups, isLoading: isSystemTraitsLoading } = useRetrieveSystemTraits({
    staleTime: Infinity,
  });

  const res = useEitherQuery(
    appTraitsRetrieveKeys.concat(appID, version),
    () => {
      if (appID) {
        return services2.appTraitsService.retrieve(appID, version);
      }
    },
    {
      keepPreviousData: true,
      ...options,
    }
  );

  const traits = useMemo(() => {
    if (res.data !== undefined) {
      try {
        return new Traits(JSON.parse(res.data.entity.content));
      } catch (e) {
        console.error(e);
        return undefined;
      }
    }
  }, [res.data]);

  const traitsWithSystemTraits = useMemo(() => {
    if (isSystemTraitsLoading) {
      return emptyArray;
    }
    if (traits?.groups) {
      const mergedTraits: TraitGroup[] = [...systemTraitsGroups];
      mergedTraits.push(
        ...traits.groups.filter((group) => !systemTraitsGroups.some((systemTrait) => systemTrait.name === group.name))
      );
      return mergedTraits;
    }
    return systemTraitsGroups;
  }, [isSystemTraitsLoading, systemTraitsGroups, traits?.groups]);

  return {
    traits,
    traitsGroups: traitsWithSystemTraits,
    version: res.data?.entity.version,
    lastVersion: res.data?.lastVersion,
    hardcodedTraits,
    ...res,
  };
};
