import { UseMutationOptions } from '@tanstack/react-query';

import { SupportRequestId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const useDeleteSupportRequestNote = (
  options?: UseMutationOptions<
    CommonSuccess,
    GenericFailure | Error,
    { id: SupportRequestId; noteId: string; env: Environment }
  >
) =>
  useEitherMutation(
    ({ id, noteId, env }) => services2.audienceSupportService.deleteSupportRequestsNote(id, noteId, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
