import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { AppID, OptionsFilter, Filter } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { AppsFilterField, App } from '@playq/octopus2-apps';

import { services2 } from '/api/services2';
import { createRequestQueue } from '/api/service-hooks';

import { appQueryTags } from './constants';

const delay = 50;

const makeFilterBy = (appsID: (AppID | undefined)[]): Record<string, Filter> => {
  const filterBy: Record<string, Filter> = {};
  const filteredAppsID = appsID.filter(Boolean) as AppID[];

  if (filteredAppsID.length > 0) {
    const options = filteredAppsID.map((appID) => appID.serialize());
    const filter = new OptionsFilter({ options });
    filterBy[AppsFilterField.ID] = filter;
  }

  return filterBy;
};

const queue = createRequestQueue<AppID | undefined, App>(
  (appsID) => {
    const filterBy = makeFilterBy(appsID);
    return new Promise((resolve, reject) => {
      services2.appsService
        .queryApps(new OffsetLimit({ offset: 0, limit: appsID.length }), [], filterBy)
        .then((data) => data.bifold((res) => resolve(res.entities), reject));
    });
  },
  {
    predicate: (appId, app) => (app ? appId?.serialize() === app.id.serialize() : false),
    timeout: delay,
  }
);

export const useAppQuery = (id?: AppID, options?: UseQueryOptions<App | undefined, GenericFailure | Error>) => {
  return useQuery<App | undefined, GenericFailure | Error>(appQueryTags.concat(id), () => queue.push(id), {
    ...options,
    enabled: id !== undefined && (options?.enabled ?? true),
  });
};
