import { QueryKey, MutationKey } from '@tanstack/react-query';

import { services2 } from '/api/services2';

export const appsService = services2.appsService;
export const appsServiceName = appsService.getClassName();

export const appQueryTags: QueryKey = ['app', 'retrieve'];
export const appsQueryTags: QueryKey = ['apps', 'service', 'query'];

export const createAppMutationKey: MutationKey = ['app', 'mutation', 'create'];
export const updateAppMutationKey: MutationKey = ['app', 'mutation', 'update'];
export const removeAppMutationKey: MutationKey = ['app', 'mutation', 'remove'];

// the same names as the names of the methods in /common/scripts/definitions/script-hooks.d.ts
export const APP_CREATION_HOOK_NAME = 'onAppCreate';
export const APP_SAVE_HOOK_NAME = 'onAppSave';
