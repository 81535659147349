import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  AttachmentSearchQuery,
  IssuesAttachmentsSearchResponse,
  IssueId,
  AppIssueBrief,
  AppIssueBriefSerialized,
} from '@playq/services-beetle';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Filter, AppID, Environment } from '@playq/octopus-common';

import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useMutateQueryDataAfterEntityDeleting,
  useQueryPrefetch,
} from '/api/service-hooks';
import { services2 } from '/api/services2';
import { snackbarService } from '/common/snackbarService';
import { useDeleteCacheBatch } from '/component/Support/BeetlesLookup/helpers/useDeleteCacheBatch';
import { countAttachment } from '/component/Support/BeetlesLookup/helpers';

export const searchAttachmentQueryKeys: unknown[] = ['bettle', 'support', 'search', 'attachments'];
export const useSearchIssueAttachments = (
  appId: AppID,
  query: AttachmentSearchQuery,
  iterator: OffsetLimit,
  env: Environment,
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptionsExtended<IssuesAttachmentsSearchResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();
  const keys = useMemo(
    () => searchAttachmentQueryKeys.concat(env, query, filterBy, iterator),
    [env, filterBy, iterator, query]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.audienceSupportService.searchIssuesByAttachments(appId, query, iterator, filterBy, env),
    {
      keepPreviousData: true,
      onError: (err) => {
        // Error can be empty in some cases
        const error = err?.message || new Error('Could not retrieve issues');
        snackbarService.genericFailure(error);
      },
      ...options,
    }
  );
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && appId !== undefined,
    [options?.enablePrefetch, appId]
  );
  const issuesWithAttachments = useMemo(() => {
    return res.data?.issues || [];
  }, [res.data?.issues]);

  const isLoading = res.isLoading && res.fetchStatus !== 'idle';
  const { nextKeys, nextIterator, preFetchData, setPreFetchData } = useQueryPrefetch({
    keys: keys,
    enabled: enablePrefetch,
    total: issuesWithAttachments.length,
    args: [appId, query, iterator, filterBy, env],
    serviceMethod: services2.audienceSupportService.searchIssuesByAttachments.bind(services2.audienceSupportService),
    hasMore: issuesWithAttachments.length === iterator.limit ? true : undefined,
  });

  const total = countAttachment(
    nextIterator,
    issuesWithAttachments.length,
    iterator,
    preFetchData?.issues.length ?? iterator.limit
  );
  useEffect(() => {
    if (issuesWithAttachments.length === nextIterator?.serialize().limit) {
      if (nextKeys) {
        setPreFetchData(queryClient.getQueryData(nextKeys));
      }
      return () => setPreFetchData(undefined);
    }
    //nextKeys  makes event-loop in deps bacause we have query(calendar date) in cache that update every seconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuesWithAttachments.length, queryClient, nextIterator, setPreFetchData]);

  const totalAttachment = useMemo(
    () => (issuesWithAttachments.length === 0 ? 0 : total),
    [issuesWithAttachments.length, total]
  );

  useEffect(() => {
    if (query.query.length > 0) {
      queryClient.removeQueries();
    }
    return;
  }, [query.query, preFetchData, iterator.limit, queryClient]);

  const mutateDeletedIssueWithAttachments = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total: totalAttachment,
    iterator,
    mutate,
    refetch,
    getID: (i?: AppIssueBrief) => i?.id,
    getEntities: (r: IssuesAttachmentsSearchResponse) => r.issues,
    setEntities: (r: IssuesAttachmentsSearchResponse, newEntities: AppIssueBrief[]) => {
      r.issues = newEntities;
    },
    createNewResponse: (prevResponse: IssuesAttachmentsSearchResponse) =>
      new IssuesAttachmentsSearchResponse(prevResponse.serialize()),
  });
  const updateIssueAttBatchStatus = (ress: { ids: IssueId[]; resolved: boolean }) => {
    mutate((prev) => {
      if (!prev) {
        return;
      }
      const newResponse = new IssuesAttachmentsSearchResponse(prev.serialize());
      const updateIssues = [...newResponse.issues];
      const selectedIssues = ress.ids.map((selectedId) => {
        return updateIssues.findIndex((issue) => issue.id.serialize() === `IssueId#${selectedId.app}:${selectedId.id}`);
      });

      newResponse.issues = updateIssues.map((value, i) => {
        if (selectedIssues.includes(i)) {
          const newIssue = value;
          newIssue.resolved = ress.resolved;
          return newIssue;
        } else {
          return value;
        }
      });

      return newResponse;
    });
  };
  const mutateUpdatedIssueAttResolved = useCallback(
    (updatedIssueId: IssueId) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const newResponse = new IssuesAttachmentsSearchResponse(prevResponse.serialize());
        newResponse.issues = prevResponse.issues.map((issue) => {
          if (issue.id.serialize() !== updatedIssueId.serialize()) {
            return issue;
          }
          const newIssue = new AppIssueBrief(issue.serialize());
          newIssue.resolved = !issue.resolved;
          return newIssue;
        });
        return newResponse;
      });
    },
    [mutate]
  );
  const removeSelectedIssueAttBatch = useDeleteCacheBatch<
    IssuesAttachmentsSearchResponse,
    IssueId,
    AppIssueBriefSerialized
  >({
    mutate,
    nextKeys: nextKeys,
    total: totalAttachment,
    getSerialize: (response) => [...response.serialize().issues],
    responseClass: (newArray, updatedIterator) =>
      new IssuesAttachmentsSearchResponse({
        issues: newArray,
        iterator: updatedIterator,
      }),
    compareIds: (issue, issueId) => issue.id === `IssueId#${issueId.app}:${issueId.id}`,
    queryClient: queryClient,
  });
  const refetchNextPage = useCallback(() => {
    if (totalAttachment > issuesWithAttachments.length) {
      return queryClient.invalidateQueries({
        queryKey: nextKeys,
        refetchType: 'none',
      });
    }
    return;
  }, [issuesWithAttachments.length, nextKeys, queryClient, totalAttachment]);

  return {
    ...res,
    isLoading,
    mutate,
    updateIssueAttBatchStatus,
    mutateUpdatedIssueAttResolved,
    mutateDeletedIssueWithAttachments,
    removeSelectedIssueAttBatch,
    refetch,
    refetchNextPage,
    total: totalAttachment,
    issues: issuesWithAttachments,
  };
};
