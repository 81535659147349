import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';

import { UseFileUploadRetryMutationType } from '../filesService';

import { uploadRetryAssetMutationKey } from './constants';

export const useAssetUploadRetry: UseFileUploadRetryMutationType = (options) =>
  useEitherMutation(
    ({ appID, id, mime, sha256 }) => services2.appAssetsService.uploadRetry(appID as AppID, id, mime, sha256),
    {
      mutationKey: uploadRetryAssetMutationKey,
      ...options,
    }
  );
