import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { ClusterizationRun, ClusterizationTaskId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const retrieveLatestRunKeys = ['retrieve', 'latest', 'run'];
export const useRetrieveLatestRun = (
  taskId: ClusterizationTaskId,
  env: Environment = Environment.Live,
  options?: UseQueryOptions<ClusterizationRun | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => retrieveLatestRunKeys.concat(taskId.serialize(), env), [env, taskId]);

  const res = useEitherQuery(
    keys,
    () => services2.audienceRequestsClusterizationService.retrieveLatestRun(taskId, env),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  return { ...res };
};
