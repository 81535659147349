import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { AppID } from '@playq/octopus-common';
import { FileFeature, FilesSort, FilesSortField, FileResponse, QueryFilesResponse } from '@playq/octopus2-files';

import { services2 } from '/api/services2';
import { useEitherQuery, useQueryPrefetch, useMutateQueryDataAfterEntityDeleting } from '/api/service-hooks';
import { IQuery } from '/common/models';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

import { UseFilesQueryOptions } from '../filesService';

import { assetsQueryKeys } from './constants';

const emptyArray: [] = [];

export const useAssetsQuery = (
  features: FileFeature[],
  query: IQuery<FilesSortField, FilesSort>,
  options?: UseFilesQueryOptions
) => {
  const appID = useSelector(appToolkit.selectors.appID);
  const queryClient = useQueryClient();

  const keys = useMemo(
    () => assetsQueryKeys.concat(appID, features, query.iterator, query.sortBy, query.filterBy),
    [appID, features, query]
  );

  const enabled = useMemo(() => (options?.enabled ?? true) && appID !== undefined, [options?.enabled, appID]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && appID !== undefined,
    [options?.enablePrefetch, appID]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.appAssetsService.queryFiles(appID as AppID, query.iterator, features, query.sortBy, query.filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, query.iterator, features, query.sortBy, query.filterBy],
    serviceMethod: services2.appAssetsService.queryFiles.bind(services2.appAssetsService),
  });

  const mutateDeletedFile = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator: query.iterator,
    mutate,
    refetch,
    getID: (fR?: FileResponse) => fR?.file?.id,
    getEntities: (r: QueryFilesResponse) => r.entities,
    setEntities: (r: QueryFilesResponse, newEntities: FileResponse[]) => {
      r.entities = newEntities;
    },
    createNewResponse: (prevResponse: QueryFilesResponse) => new QueryFilesResponse(prevResponse.serialize()),
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(assetsQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    files: res.data?.entities ?? emptyArray,
    total,
    tags: keys,
    mutateDeletedFile,
    removeQueries,
    refetch,
  };
};
