import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { FileResponse } from '@playq/octopus2-files';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';
import { FILE_UPLOAD_HOOK_NAME } from '../filesService';

import { IAssetUpdateRevisionOptionsVariables, IUpdateRevisionParams } from './types';
import { assetsService, assetsServiceName, updateAssetRevisionMutationKey } from './constants';

export const useAssetUpdateRevisionScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    IAssetUpdateRevisionOptionsVariables,
    FileResponse
  >
) =>
  useEitherMutationScripted<IUpdateRevisionParams, CommonSuccess, IInvokeScriptsHook, FileResponse>({
    serviceMethod: assetsService.updateRevision.bind(assetsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: assetsServiceName,
      mutationType: FILE_UPLOAD_HOOK_NAME,
    },
    options: {
      onError: onResponseError,
      mutationKey: updateAssetRevisionMutationKey,
      ...options,
    },
  });
