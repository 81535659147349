import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppInventoryItemResponse } from '@playq/octopus2-economy';
import { ItemID } from '@playq/services-bookkeeper';

import { useEitherQuery } from '/api/service-hooks';

import { appsInventoryRetrieveKeys, appsInventoryService } from './constants';
// import { TUpdateInventoryItemParams } from './types';

export const useAppsInventoryRetrieve = (
  id?: ItemID,
  version?: number,
  options?: UseQueryOptions<AppInventoryItemResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    appsInventoryRetrieveKeys.concat(id, version),
    () => appsInventoryService.retrieve(id as ItemID, version),
    {
      keepPreviousData: true,
      staleTime: 10000,
      ...options,
      enabled: options?.enabled !== false && id !== undefined,
    }
  );

  return {
    ...res,
    mutate,
  };
};
