import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { IssueId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type DeleteIssuePayloadType = {
  id: IssueId;
  env: Environment;
};

export const deleteIssueMutationKey: MutationKey = ['issue', 'muatation', 'delete'];

export const useDeleteIssue = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, DeleteIssuePayloadType>
) =>
  useEitherMutation(({ id, env }) => services2.audienceSupportService.deleteIssue(id, env), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: deleteIssueMutationKey,
    ...options,
  });
