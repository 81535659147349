import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppSupportRequest, SupportRequestId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { handleEntriesMeta } from '/component/Support/BeetlesLookup/helpers';

import { addNote, removeNote, updateNote } from '../../../component/Support/BeetlesLookup/content/cache';

import { audienceSupportQueryKeys } from './useQuerySupportRequests';

const emptyArray: [] = [];

const emptySupportRequest = new AppSupportRequest();

export const useRetrieveSupportRequest = (
  id: SupportRequestId,
  env: Environment = Environment.Live,
  options?: UseQueryOptions<AppSupportRequest | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => audienceSupportQueryKeys.concat('retrieve', 'supportRequest', id, env), [id, env]);

  const res = useEitherQuery(keys, () => services2.audienceSupportService.retrieveSupportRequest(id, env), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
  const cacheOptimization = useMemo(() => {
    return {
      addNote: addNote<AppSupportRequest>((oldData) => new AppSupportRequest(oldData.serialize()), res.mutate),
      removeNote: removeNote<AppSupportRequest>((oldData) => new AppSupportRequest(oldData.serialize()), res.mutate),
      updateNote: updateNote<AppSupportRequest>((oldData) => new AppSupportRequest(oldData.serialize()), res.mutate),
    };
  }, [res.mutate]);
  const metaEntries = useMemo(() => handleEntriesMeta(res.data?.meta), [res.data?.meta]);
  return {
    ...res,
    updatedAt: res.dataUpdatedAt,
    request: res.data || emptySupportRequest,
    history: res.data?.history || emptyArray,
    cacheOptimization,
    metaEntries,
  };
};
