import { GenericFailure } from '@playq/services-shared';
import { IconSource, App } from '@playq/octopus2-apps';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { updateAppMutationKey } from './constants';

export const useAppUpdate = (
  options?: UseMutationOptionsExtended<App, GenericFailure | Error, { app: App; iconFile: IconSource | undefined }>
) =>
  useEitherMutation(({ app, iconFile }) => services2.appsService.update(app.id, app.toAppDetails(), iconFile), {
    mutationKey: updateAppMutationKey,
    ...options,
  });
