import { CredentialsCreateData, Credentials } from '@playq/octopus2-auth';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { createCredentialMutationKeys } from './constants';

export const useCreateCredentials = (
  options?: UseMutationOptionsExtended<Credentials, GenericFailure | Error, CredentialsCreateData>
) =>
  useEitherMutation((data: CredentialsCreateData) => services2.adminCredentialsManagementService.create(data), {
    onError: (err) => {
      const errorToDisplay = err?.message ? err : new Error('Invalid data');
      snackbarService.genericFailure(errorToDisplay);
    },
    mutationKey: createCredentialMutationKeys,
    ...options,
  });
