import { GenericFailure } from '@playq/services-shared';
import { App } from '@playq/octopus2-apps';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { appsService, appsServiceName, createAppMutationKey } from './constants';
import { ICreateAppParams, IAppCreateOptionsVariables } from './types';

export const useAppCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<App, GenericFailure | Error, IAppCreateOptionsVariables>
) =>
  useEitherMutationScripted<ICreateAppParams, App, IInvokeScriptsHook>({
    serviceMethod: appsService.create.bind(appsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appsServiceName,
      mutationType: 'app creation',
    },
    options: {
      mutationKey: createAppMutationKey,
      onError: onResponseError,
      ...options,
    },
  });
