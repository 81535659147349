import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CheckFilesBatchResponse } from '@playq/octopus2-files';
import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { UseCheckFilesBatchType } from '../filesService/useCheckFilesBatch';

const emptyArray: [] = [];

export const useCheckAssetsBatch: UseCheckFilesBatchType = (
  { appID, batch },
  options?: UseQueryOptions<CheckFilesBatchResponse | undefined, GenericFailure | Error>
) => {
  const keys = ['appAssetsService', 'checkFilesBatch', ...batch.map((f) => f.serialize())];

  const enabled = useMemo(
    () => options?.enabled !== false && batch !== undefined && !!appID,
    [appID, batch, options?.enabled]
  );

  const res = useEitherQuery(keys, () => services2.appAssetsService.checkFilesBatch(appID as AppID, batch), {
    onError: console.error,
    ...options,
    enabled,
  });

  return {
    ...res,
    checkedFiles: res.data?.batch ?? emptyArray,
  };
};
