import { GenericFailure } from '@playq/services-shared';
import { AppInventoryItem } from '@playq/octopus2-economy';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { appsInventoryService, appsInventoryServiceName, createItemMutationKey } from './constants';
import { IAppsInventoryCreateOptionsVariables, ICreateInventoryItemParams } from './types';

export const useAppsInventoryCreateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<AppInventoryItem, GenericFailure | Error, IAppsInventoryCreateOptionsVariables>
) =>
  useEitherMutationScripted<ICreateInventoryItemParams, AppInventoryItem, IInvokeScriptsHook>({
    serviceMethod: appsInventoryService.create.bind(appsInventoryService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appsInventoryServiceName,
      mutationType: 'item creation',
    },
    options: {
      onError: onResponseError,
      mutationKey: createItemMutationKey,
      ...options,
    },
  });
