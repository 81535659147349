import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  AttachmentSearchQuery,
  SupportRequestsAttachmentSearchResponse,
  AppSupportRequestBriefSerialized,
} from '@playq/services-beetle';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Filter, AppID, Environment } from '@playq/octopus-common';
import { SupportRequestId, RequestStatus, AppSupportRequestBrief } from '@playq/services-beetle';

import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useMutateQueryDataAfterEntityDeleting,
  useQueryPrefetch,
} from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { services2 } from '/api/services2';
import { useDeleteCacheBatch } from '/component/Support/BeetlesLookup/helpers/useDeleteCacheBatch';
import { countAttachment } from '/component/Support/BeetlesLookup/helpers';

export const searchSupportAttachmentQueryKeys: unknown[] = [
  'bettle',
  'support',
  'search',
  'attachments',
  'support_request',
];
export const useSearchSupportRequestAttachments = (
  appId: AppID,
  query: AttachmentSearchQuery,
  iterator: OffsetLimit,
  env: Environment,
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptionsExtended<SupportRequestsAttachmentSearchResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(
    () => searchSupportAttachmentQueryKeys.concat(env, query, filterBy, iterator),
    [env, filterBy, iterator, query]
  );
  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.audienceSupportService.searchSupportRequestsByAttachments(appId, query, iterator, filterBy, env),
    {
      onError: (err) => {
        // Error can be empty in some cases
        const error = err?.message || new Error('Could not retrieve issues');
        snackbarService.genericFailure(error);
      },
      ...options,
    }
  );
  const queryClient = useQueryClient();

  const enablePrefetch = useMemo(() => options?.enablePrefetch ?? true, [options?.enablePrefetch]);
  const supportRequestWithAttachments = useMemo(() => res.data?.requests || [], [res.data?.requests]);

  const { nextKeys, nextIterator, preFetchData, setPreFetchData } = useQueryPrefetch({
    keys: keys,
    enabled: enablePrefetch,
    total: supportRequestWithAttachments.length,
    args: [appId, query, iterator, filterBy, env],
    serviceMethod: services2.audienceSupportService.searchSupportRequestsByAttachments.bind(
      services2.audienceSupportService
    ),
    hasMore: supportRequestWithAttachments.length === iterator.limit ? true : undefined,
  });

  useEffect(() => {
    if (supportRequestWithAttachments.length === nextIterator?.serialize().limit) {
      if (nextKeys) {
        setPreFetchData(queryClient.getQueryData(nextKeys));
      }
    }
    return () => setPreFetchData(undefined);
    //nextKeys  makes event-loop in deps bacause we have query(calendar date) in cache that update every seconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportRequestWithAttachments.length, queryClient, nextIterator, setPreFetchData]);

  const total = countAttachment(
    nextIterator,
    supportRequestWithAttachments.length,
    iterator,
    preFetchData?.requests.length ?? iterator.limit
  );

  const totalAttachment = useMemo(
    () => (supportRequestWithAttachments.length === 0 ? 0 : total),
    [supportRequestWithAttachments.length, total]
  );

  const mutateUpdatedSupportRequestAttStatus = useCallback(
    (updatedRequestId: SupportRequestId, status: RequestStatus) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const newResponse = new SupportRequestsAttachmentSearchResponse(prevResponse.serialize());
        newResponse.requests = prevResponse.requests.map((request) => {
          if (request.id.serialize() !== updatedRequestId.serialize()) {
            return request;
          }
          const newRequest = new AppSupportRequestBrief(request.serialize());
          newRequest.status = status;
          return newRequest;
        });
        return newResponse;
      });
    },
    [mutate]
  );
  const updateSupportAttBatchStatus = ({ ids, status }: { ids: SupportRequestId[]; status: RequestStatus }) => {
    mutate((prev) => {
      if (!prev) {
        return;
      }
      const newResponse = new SupportRequestsAttachmentSearchResponse(prev.serialize());
      const updateSupport = [...newResponse.requests];
      const selectedIssues = ids.map((supportId) => {
        return updateSupport.findIndex(
          (item) => item.id.serialize() === `SupportRequestId#${supportId.app}:${supportId.id}`
        );
      });
      newResponse.requests = updateSupport.map((value, i) => {
        if (selectedIssues.includes(i)) {
          const newRequest = value;
          newRequest.status = status;
          return newRequest;
        } else {
          return value;
        }
      });
      return newResponse;
    });
  };

  const mutateDeletedSupportRequestAttachment = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total: totalAttachment,
    iterator,
    mutate,
    refetch,
    getID: (r?: AppSupportRequestBrief) => r?.id,
    getEntities: (r: SupportRequestsAttachmentSearchResponse) => r.requests,
    setEntities: (r: SupportRequestsAttachmentSearchResponse, newEntities: AppSupportRequestBrief[]) => {
      r.requests = newEntities;
    },
    createNewResponse: (prevResponse: SupportRequestsAttachmentSearchResponse) =>
      new SupportRequestsAttachmentSearchResponse(prevResponse.serialize()),
  });
  const removeSelectedSupportAttBatch = useDeleteCacheBatch<
    SupportRequestsAttachmentSearchResponse,
    SupportRequestId,
    AppSupportRequestBriefSerialized
  >({
    mutate,
    total: totalAttachment,
    nextKeys,
    getSerialize: (response) => [...response.serialize().requests],
    responseClass: (newArray, updatedIterator) =>
      new SupportRequestsAttachmentSearchResponse({
        requests: newArray,
        iterator: updatedIterator,
      }),
    compareIds: (request, requestId) => request.id === `SupportRequestId#${requestId.app}:${requestId.id}`,
    queryClient: queryClient,
  });
  const isLoading = res.isLoading && res.fetchStatus !== 'idle';

  const refetchNextPage = useCallback(() => {
    if (totalAttachment > supportRequestWithAttachments.length) {
      return queryClient.invalidateQueries({
        queryKey: nextKeys,
        refetchType: 'none',
      });
    }
    return;
  }, [supportRequestWithAttachments.length, nextKeys, queryClient, totalAttachment]);

  return {
    ...res,
    isLoading,
    mutate,
    refetch,
    total: totalAttachment,
    mutateUpdatedSupportRequestAttStatus,
    mutateDeletedSupportRequestAttachment,
    updateSupportAttBatchStatus,
    removeSelectedSupportAttBatch,
    requests: supportRequestWithAttachments,
    refetchNextPage,
  };
};
