import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure } from '@playq/services-shared';
import { SystemTraits } from '@playq/octopus2-analytics';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';

export const appTraitsRetrieveKeys: unknown[] = ['app', 'traits', 'retrieve'];
export const appRetrieveSystemTraitsKeys: unknown[] = appTraitsRetrieveKeys.concat('system');

const emptyArray: [] = [];

export const useRetrieveSystemTraits = (
  options?: UseQueryOptions<SystemTraits | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const res = useEitherQuery(
    appRetrieveSystemTraitsKeys.concat(appID),
    () => {
      if (appID !== undefined) {
        return services2.appTraitsService.retrieveSystemTraits(appID);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  return { ...res, groups: res.data?.groups || emptyArray };
};
