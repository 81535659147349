import { CredentialsCreateData, CredentialsID } from '@playq/octopus2-auth';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { updateCredentialMutationKeys } from './constants';

type UpdateCredentialsPayload = {
  id: CredentialsID;
  data: CredentialsCreateData;
};

export const useUpdateCredentials = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, UpdateCredentialsPayload>
) =>
  useEitherMutation(
    ({ id, data }: UpdateCredentialsPayload) => services2.adminCredentialsManagementService.update(id, data),
    {
      onError: (err) => {
        const errorToDisplay = err?.message ? err : new Error('Invalid data');
        snackbarService.genericFailure(errorToDisplay);
      },
      mutationKey: updateCredentialMutationKeys,
      ...options,
    }
  );
