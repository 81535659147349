import { UseMutationResult } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';

import { IAppsInventoryUpdateOptionsVariables, IUpdateInventoryItemParams } from './types';
import { appsInventoryService, appsInventoryServiceName, updateItemMutationKey } from './constants';

export const useAppsInventoryUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, IAppsInventoryUpdateOptionsVariables>
): UseMutationResult<CommonSuccess, GenericFailure | Error, IAppsInventoryUpdateOptionsVariables> =>
  useEitherMutationScripted<IUpdateInventoryItemParams, CommonSuccess, IInvokeScriptsHook>({
    serviceMethod: appsInventoryService.update.bind(appsInventoryService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appsInventoryServiceName,
      mutationType: 'item update',
    },
    options: {
      mutationKey: updateItemMutationKey,
      onError: onResponseError,
      ...options,
    },
  });
