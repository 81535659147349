import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { ClusterizationRunId, ClusterReport, ClusterId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { useEitherQuery } from '/api/service-hooks';
import { services2 } from '/api';
import { snackbarService } from '/common/snackbarService';

const reportKeys: unknown[] = ['cluster', 'query'];
export const useQueryCluster = (
  runId: ClusterizationRunId,
  clusterId: ClusterId,
  iterator: OffsetLimit,
  env: Environment,
  options?: UseQueryOptions<ClusterReport | undefined, GenericFailure | Error>
) => {
  const { mutate, refetch, ...res } = useEitherQuery(
    reportKeys.concat(runId, clusterId, env, iterator),
    () => {
      return services2.audienceRequestsClusterizationService.queryCluster(runId, clusterId, iterator, env);
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  return { ...res };
};
