import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { App } from '@playq/octopus2-apps';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { removeAppMutationKey } from './constants';

export const useAppDelete = (options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, App>) =>
  useEitherMutation((app) => services2.appsService.delete(app.id), {
    mutationKey: removeAppMutationKey,
    ...options,
  });
