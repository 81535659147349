import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import {
  SupportRequestsResponse,
  SupportRequestSort,
  SupportRequestId,
  RequestStatus,
  AppSupportRequestBrief,
  AppSupportRequestBriefSerialized,
} from '@playq/services-beetle';
import { AppID, Environment, Filter } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useQueryPrefetch,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { QueryHelpers } from '/helpers/query';
import { useDeleteCacheBatch } from '/component/Support/BeetlesLookup/helpers/useDeleteCacheBatch';

const emptyArray: [] = [];
const initialIterator = QueryHelpers.getInitIterator();

export const audienceSupportQueryKeys: unknown[] = ['service', 'audience', 'support'];

export const supportRequestsQueryKeys: unknown[] = audienceSupportQueryKeys.concat('query', 'supportRequests');

export const useQuerySupportRequests = (
  env: Environment = Environment.Sandbox,
  iterator: OffsetLimit = initialIterator,
  sortBy: SupportRequestSort[] = [],
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptionsExtended<SupportRequestsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();
  const appID: AppID | undefined = useSelector(appToolkit.selectors.appID);

  const keys = useMemo(
    () => supportRequestsQueryKeys.concat(appID, iterator, env, sortBy, filterBy),
    [appID, iterator, env, sortBy, filterBy]
  );

  const enabled = useMemo(() => (options?.enabled ?? true) && appID !== undefined, [options?.enabled, appID]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && appID !== undefined,
    [options?.enablePrefetch, appID]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.audienceSupportService.querySupportRequests(appID as AppID, iterator, sortBy, filterBy, env),
    {
      keepPreviousData: true,
      onError: (err) => {
        // Error can be empty in some cases
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const error = err || new Error('Could not retrieve support requests');
        snackbarService.genericFailure(error);
      },
      ...options,
      enabled,
    }
  );

  const requests = useMemo(() => res.data?.requests || emptyArray, [res.data?.requests]);
  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy, env],
    serviceMethod: services2.audienceSupportService.querySupportRequests.bind(services2.audienceSupportService),
  });

  const mutateDeletedSupportRequest = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (r?: AppSupportRequestBrief) => r?.id,
    getEntities: (r: SupportRequestsResponse) => r.requests,
    setEntities: (r: SupportRequestsResponse, newEntities: AppSupportRequestBrief[]) => {
      r.requests = newEntities;
    },
    createNewResponse: (prevResponse: SupportRequestsResponse) => new SupportRequestsResponse(prevResponse.serialize()),
  });
  const removeSelectedBatch = useDeleteCacheBatch<
    SupportRequestsResponse,
    SupportRequestId,
    AppSupportRequestBriefSerialized
  >({
    mutate,
    total,
    nextKeys,
    getSerialize: (response) => [...response.serialize().requests],
    responseClass: (newArray, updatedIterator, updatedTotal) =>
      new SupportRequestsResponse({
        requests: newArray,
        iterator: updatedIterator,
        total: updatedTotal,
      }),
    compareIds: (request, requestId) => request.id === `SupportRequestId#${requestId.app}:${requestId.id}`,
    queryClient: queryClient,
  });

  const mutateUpdatedSupportRequestStatus = useCallback(
    (updatedRequestId: SupportRequestId, status: RequestStatus) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const newResponse = new SupportRequestsResponse(prevResponse.serialize());
        newResponse.requests = prevResponse.requests.map((request) => {
          if (request.id.serialize() !== updatedRequestId.serialize()) {
            return request;
          }
          const newRequest = new AppSupportRequestBrief(request.serialize());
          newRequest.status = status;
          return newRequest;
        });
        return newResponse;
      });
    },
    [mutate]
  );
  const updateSupportBatchStatus = ({ ids, status }: { ids: SupportRequestId[]; status: RequestStatus }) => {
    mutate((prev) => {
      if (!prev) {
        return;
      }
      const newResponse = new SupportRequestsResponse(prev.serialize());
      const updateSupport = [...newResponse.requests];
      const selectedSupport = ids.map((supportId) => {
        return updateSupport.findIndex(
          (item) => item.id.serialize() === `SupportRequestId#${supportId.app}:${supportId.id}`
        );
      });

      newResponse.requests = updateSupport.map((value, i) => {
        if (selectedSupport.includes(i)) {
          const newRequest = value;
          newRequest.status = status;
          return newRequest;
        } else {
          return value;
        }
      });
      return newResponse;
    });
  };
  const removeQueries = useCallback(() => {
    queryClient.removeQueries(supportRequestsQueryKeys);
  }, [queryClient]);
  const refetchNextPage = useCallback(() => {
    if (total > iterator.limit + iterator.offset) {
      return queryClient.invalidateQueries({ queryKey: nextKeys, refetchType: 'all' });
    }
    return;
  }, [iterator, nextKeys, queryClient, total]);
  const isLoading = res.isLoading && res.fetchStatus !== 'idle';

  return {
    ...res,
    isLoading,
    tags: keys,
    requests,
    mutateDeletedSupportRequest,
    mutateUpdatedSupportRequestStatus,
    removeQueries,
    refetch,
    removeSelectedBatch,
    updateSupportBatchStatus,
    refetchNextPage,
    total,
  };
};
