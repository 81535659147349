import { MutationKey, QueryKey } from '@tanstack/react-query';

import { AppsPackagesServiceClient } from '@playq/octopus2-economy';

import { services2 } from '/api/services2';

export const appsPackagesQueryKeys: QueryKey = ['apps', 'packages', 'query'];

export const appsPackagesService: AppsPackagesServiceClient = services2.appsPackagesService;
export const appsPackagesServiceName: string = appsPackagesService.getClassName();

export const createPackageMutationKey: MutationKey = ['package', 'mutation', 'create'];
export const updatePackageMutationKey: MutationKey = ['package', 'mutation', 'update'];
export const removePackageMutationKey: MutationKey = ['package', 'mutation', 'remove'];
