import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { InventoryItemBrief } from '@playq/octopus2-economy';

import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { appsInventoryService, removeItemMutationKey } from './constants';

export const useAppsInventoryDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, InventoryItemBrief>
) =>
  useEitherMutation((item) => appsInventoryService.delete(item.id), {
    mutationKey: removeItemMutationKey,
    ...options,
  });
