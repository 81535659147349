import { useCallback, useMemo } from 'react';
import { Updater, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Filter, AppID } from '@playq/octopus-common';
import {
  ItemsSort,
  QueryInventoryItemsResponse,
  InventoryItemPromotions,
  InventoryItemBrief,
} from '@playq/octopus2-economy';
import { ItemID } from '@playq/services-bookkeeper';

import {
  useEitherQuery,
  useQueryPrefetch,
  UseQueryOptionsExtended,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { iteratorMaxLimit } from '/constants';

import { IEntityPromotion } from '../promotions';

import { appsInventoryService, appsInventoryQueryKeys } from './constants';

const emptyArray: [] = [];

const defaultQuery = {
  iterator: new OffsetLimit({ limit: iteratorMaxLimit, offset: 0 }),
  sortBy: [],
  filterBy: {},
};

export const useAppsInventoryQuery = (
  iterator: OffsetLimit = defaultQuery.iterator,
  sortBy: ItemsSort[] = defaultQuery.sortBy,
  filterBy: {
    [key: string]: Filter;
  } = defaultQuery.filterBy,
  options?: UseQueryOptionsExtended<QueryInventoryItemsResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const queryClient = useQueryClient();

  const keys = useMemo(
    () => appsInventoryQueryKeys.concat(appID, iterator, sortBy, filterBy),
    [appID, iterator, sortBy, filterBy]
  );

  const enabled = useMemo(() => (options?.enabled ?? true) && appID !== undefined, [options?.enabled, appID]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && appID !== undefined,
    [options?.enablePrefetch, appID]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => appsInventoryService.queryItems(appID as AppID, iterator, sortBy, filterBy),
    {
      onError: (err) => snackbarService.genericFailure(err),
      keepPreviousData: true,
      ...options,
      enabled,
    }
  );
  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    args: [appID as AppID, iterator, sortBy, filterBy],
    total,
    serviceMethod: appsInventoryService.queryItems.bind(appsInventoryService),
  });

  const mutateUpdatePromotions = useCallback(
    (promotions: Updater<IEntityPromotion<ItemID>[], IEntityPromotion<ItemID>[]>) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const response = new QueryInventoryItemsResponse(prevResponse.serialize());
        response.items = prevResponse.items;
        response.iterator = prevResponse.iterator;
        response.features = prevResponse.features;
        response.total = prevResponse.total;
        const nextPromtions = typeof promotions === 'function' ? promotions(response.promotions) : promotions;
        response.promotions = nextPromtions.map(
          (promotion) =>
            new InventoryItemPromotions({ id: promotion.id.serialize(), promotions: promotion.promotions.serialize() })
        );
        return response;
      });
    },
    [mutate]
  );
  const mutateDeletedItem = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (item?: InventoryItemBrief) => item?.id,
    getEntities: (entity: QueryInventoryItemsResponse) => entity.items,
    setEntities: (entity: QueryInventoryItemsResponse, items: InventoryItemBrief[]) => {
      entity.items = items;
    },
    createNewResponse: (prevResponse: QueryInventoryItemsResponse) =>
      new QueryInventoryItemsResponse(prevResponse.serialize()),
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(appsInventoryQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    items: res?.data?.items || emptyArray,
    features: res?.data?.features || [],
    promotions: res?.data?.promotions || ([] as IEntityPromotion<ItemID>[]),
    total,
    tags: keys,
    mutatePromotion: mutateUpdatePromotions,
    mutateDeletedItem,
    removeQueries,
    refetch,
  };
};
