import { MutationKey } from '@tanstack/query-core';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { Environment, AppID } from '@playq/octopus-common';

import { UseMutationOptionsExtended, useEitherMutation } from '/api/service-hooks';
import { services2 } from '/api/services2';
import { snackbarService } from '/common/snackbarService';

const dropIndexKey: MutationKey = ['attachments', 'index', 'drop'];
export const useDroIndexpAttachmentsFor = (
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    { env: Environment; appId: AppID; from_: Date; to: Date }
  >
) =>
  useEitherMutation(
    ({ appId, from_: from, to, env }) => services2.audienceSupportService.dropAttachmentsIndexFor(appId, from, to, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: dropIndexKey,
      ...options,
    }
  );
