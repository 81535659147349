import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { UseDeleteFileRevisionType } from '../filesService';

export const useDeleteAssetRevision: UseDeleteFileRevisionType = (options) => {
  return useEitherMutation(({ appID, id }) => services2.appAssetsService.deleteRevision(appID as AppID, id), {
    onError: () => {
      snackbarService.error('Could not delete the revision');
    },
    onSuccess: () => {
      snackbarService.success('Revision deleted successfully');
    },
    ...options,
  });
};
