import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { PackagesPolicy } from '@playq/octopus2-economy';

import { useEitherQuery } from '/api/service-hooks';

import { appsPackagesService } from './constants';

export const appsPackagePolicyKeys = ['apps', 'package', 'policy'];

export const useAppsPackagePolicy = (options?: UseQueryOptions<PackagesPolicy | undefined, GenericFailure | Error>) => {
  const res = useEitherQuery(appsPackagePolicyKeys, () => appsPackagesService.policy(), options);
  const data = res.data instanceof PackagesPolicy ? res.data : new PackagesPolicy();

  return { ...res, data, keys: appsPackagePolicyKeys };
};
