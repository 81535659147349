import { GenericFailure } from '@playq/services-shared';
import { FileResponse, UploadSingleResponse } from '@playq/octopus2-files';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';
import { onResponseError } from '../helpers';
import { FILE_UPLOAD_HOOK_NAME } from '../filesService';

import { IAssetUploadOptionsVariables, IUploadAssetParams } from './types';
import { assetsService, assetsServiceName, uploadAssetMutationKey } from './constants';

export const useAssetUploadScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    UploadSingleResponse,
    GenericFailure | Error,
    IAssetUploadOptionsVariables,
    FileResponse
  >
) =>
  useEitherMutationScripted<IUploadAssetParams, UploadSingleResponse, IInvokeScriptsHook, FileResponse>({
    serviceMethod: assetsService.uploadFileEntity.bind(assetsService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: assetsServiceName,
      mutationType: FILE_UPLOAD_HOOK_NAME,
    },
    options: {
      onError: onResponseError,
      mutationKey: uploadAssetMutationKey,
      ...options,
    },
  });
