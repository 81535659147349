import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { IssueId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const updateIssuesStatusMutationKey: MutationKey = ['issue', 'mutation', 'updateIssueStatus'];

export const useUpdateIssueStatus = (
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    { id: IssueId; resolved: boolean; env: Environment }
  >
) =>
  useEitherMutation(({ id, resolved, env }) => services2.audienceSupportService.updateIssueStatus(id, resolved, env), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: updateIssuesStatusMutationKey,
    ...options,
  });
