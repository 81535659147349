import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { SupportRequestId, RequestStatus } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const updateRequestStatusBatchMutationKey: MutationKey = ['issue', 'mutation', 'updateIssueStatus', 'batch'];

export const useUpdateRequestsStatus = (
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    { ids: SupportRequestId[]; status: RequestStatus; env: Environment }
  >
) =>
  useEitherMutation(
    ({ ids, status, env }) => services2.audienceSupportService.updateRequestStatusBatch(ids, status, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: updateRequestStatusBatchMutationKey,
      ...options,
    }
  );
