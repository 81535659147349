import { CommonSuccess, GenericFailure } from '@playq/services-shared';
import { PortalAppPackage } from '@playq/octopus2-economy';

import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';

import { appsPackagesService, removePackageMutationKey } from './constants';

export const useAppsPackageDelete = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, PortalAppPackage>
) =>
  useEitherMutation((appPackage) => appsPackagesService.deletePackage(appPackage.content.id), {
    mutationKey: removePackageMutationKey,
    ...options,
  });
