import { MutationKey } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';
import { ClusterizationTaskCreateData, ClusterizationTaskValidation } from '@playq/services-beetle';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const validateKey: MutationKey = ['validate', 'mutation'];

export const useValidateTask = (
  options?: UseMutationOptionsExtended<
    ClusterizationTaskValidation,
    GenericFailure | Error,
    {
      clusterTask: ClusterizationTaskCreateData;
      env: Environment;
    }
  >
) => {
  return useEitherMutation(
    ({ clusterTask, env }) => services2.audienceRequestsClusterizationService.validate(clusterTask, env),
    {
      // onSuccess: () => {
      //   queryClient.invalidateQueries(['tasks', 'query']);
      // },
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: validateKey,
      ...options,
    }
  );
};
