import { QueryKey, MutationKey } from '@tanstack/react-query';

import { AppsInventoryServiceClient } from '@playq/octopus2-economy';

import { services2 } from '/api/services2';

export const appsInventoryQueryKeys: QueryKey = ['apps', 'inventory', 'query'];

export const appsInventoryService: AppsInventoryServiceClient = services2.appsInventoryService;
export const appsInventoryServiceName: string = appsInventoryService.getClassName();

export const createItemMutationKey: MutationKey = ['inventory', 'item', 'create'];
export const updateItemMutationKey: MutationKey = ['inventory', 'item', 'update'];
export const removeItemMutationKey: MutationKey = ['inventory', 'item', 'remove'];
export const appsInventoryRetrieveKeys: unknown[] = ['apps', 'inventory', 'retrieve'];
