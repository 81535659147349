// import { MutationKey, useQueryClient } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { ClusterizationTaskId, ClusterizationRunId, IngestionConfig } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

// export const runKeys: MutationKey = ['run', 'mutation', 'task'];

export const useRunTask = (
  options?: UseMutationOptionsExtended<
    ClusterizationRunId,
    GenericFailure | Error,
    {
      taskId: ClusterizationTaskId;
      ingestionConfig: IngestionConfig;
      env: Environment;
    }
  >
) => {
  // const queryClient = useQueryClient();
  return useEitherMutation(
    ({ taskId, ingestionConfig, env }) =>
      services2.audienceRequestsClusterizationService.start(taskId, ingestionConfig, env),
    {
      // onSuccess: () => {
      //   queryClient.invalidateQueries(['tasks', 'query']);
      // },
      onError: (err) => snackbarService.genericFailure(err),
      // mutationKey: runKeys,
      ...options,
    }
  );
};
