import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CreativeAssetsPolicy } from '@playq/octopus2-files';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { assetsPolicy } from './constants';

export const useAssetsPolicy = (
  options?: UseQueryOptions<CreativeAssetsPolicy | undefined, GenericFailure | Error>
) => {
  return useEitherQuery(assetsPolicy, () => services2.appAssetsService.policy(), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
};
