import { useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { IssuesResponse, IssueSort, IssueId, AppIssueBrief, AppIssueBriefSerialized } from '@playq/services-beetle';
import { AppID, Environment, Filter } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import {
  useEitherQuery,
  useQueryPrefetch,
  UseQueryOptionsExtended,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { QueryHelpers } from '/helpers/query';
import { useDeleteCacheBatch } from '/component/Support/BeetlesLookup/helpers/useDeleteCacheBatch';

import { audienceSupportQueryKeys } from './useQuerySupportRequests';

const emptyArray: [] = [];
const initialIterator = QueryHelpers.getInitIterator();

export const issueKeys: unknown[] = audienceSupportQueryKeys.concat('query', 'issues');

export const useQueryIssues = (
  env: Environment = Environment.Sandbox,
  iterator: OffsetLimit = initialIterator,
  sortBy: IssueSort[] = [],
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptionsExtended<IssuesResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const appID = useSelector(appToolkit.selectors.appID);

  const keys = useMemo(
    () => issueKeys.concat(appID, iterator, sortBy, filterBy, env),
    [appID, iterator, env, sortBy, filterBy]
  );

  const enabled = useMemo(() => (options?.enabled ?? true) && appID !== undefined, [options?.enabled, appID]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && appID !== undefined,
    [options?.enablePrefetch, appID]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.audienceSupportService.queryIssues(appID as AppID, iterator, sortBy, filterBy, env),
    {
      keepPreviousData: true,
      onError: (err) => {
        // Error can be empty in some cases
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const error = err || new Error('Could not retrieve issues');
        snackbarService.genericFailure(error);
      },
      ...options,
      enabled,
    }
  );

  const issues = useMemo(() => res.data?.issues || emptyArray, [res.data?.issues]);
  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy, env],
    serviceMethod: services2.audienceSupportService.queryIssues.bind(services2.audienceSupportService),
  });

  const mutateDeletedIssue = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (i?: AppIssueBrief) => i?.id,
    getEntities: (r: IssuesResponse) => r.issues,
    setEntities: (r: IssuesResponse, newEntities: AppIssueBrief[]) => {
      r.issues = newEntities;
    },
    createNewResponse: (prevResponse: IssuesResponse) => new IssuesResponse(prevResponse.serialize()),
  });
  const removeSelectedBatch = useDeleteCacheBatch<IssuesResponse, IssueId, AppIssueBriefSerialized>({
    mutate,
    total,
    nextKeys,
    getSerialize: (response) => [...response.serialize().issues],
    responseClass: (newArray, updatedIterator, updatedTotal) =>
      new IssuesResponse({
        issues: newArray,
        iterator: updatedIterator,
        total: updatedTotal,
      }),
    compareIds: (issue, issueId) => issue.id === `IssueId#${issueId.app}:${issueId.id}`,
    queryClient: queryClient,
  });
  const mutateUpdatedIssueResolved = useCallback(
    (updatedIssueId: IssueId) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return;
        }
        const newResponse = new IssuesResponse(prevResponse.serialize());
        newResponse.issues = prevResponse.issues.map((issue) => {
          if (issue.id.serialize() !== updatedIssueId.serialize()) {
            return issue;
          }
          const newIssue = new AppIssueBrief(issue.serialize());
          newIssue.resolved = !issue.resolved;
          return newIssue;
        });
        return newResponse;
      });
    },
    [mutate]
  );
  const isLoading = res.isLoading && res.fetchStatus !== 'idle';
  const removeQueries = useCallback(() => {
    queryClient.removeQueries(issueKeys);
  }, [queryClient]);

  const refetchNextPage = useCallback(() => {
    if (total > iterator.limit + iterator.offset) {
      return queryClient.invalidateQueries({ queryKey: nextKeys, refetchType: 'all' });
    }
    return;
  }, [iterator, nextKeys, queryClient, total]);
  const updateIssueBatchStatus = (ress: { ids: IssueId[]; resolved: boolean }) => {
    mutate((prev) => {
      if (!prev) {
        return;
      }
      const newResponse = new IssuesResponse(prev.serialize());
      const updateIssues = [...newResponse.issues];
      const selectedIssues = ress.ids.map((selectedId) => {
        return updateIssues.findIndex((issue) => issue.id.serialize() === `IssueId#${selectedId.app}:${selectedId.id}`);
      });

      newResponse.issues = updateIssues.map((value, i) => {
        if (selectedIssues.includes(i)) {
          const newIssue = value;
          newIssue.resolved = ress.resolved;
          return newIssue;
        } else {
          return value;
        }
      });

      return newResponse;
    });
  };
  return {
    ...res,
    isLoading,
    total,
    issues,
    tags: keys,
    mutateDeletedIssue,
    mutateUpdatedIssueResolved,
    removeQueries,
    refetch,
    removeSelectedBatch,
    updateIssueBatchStatus,
    refetchNextPage,
  };
};
