import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { UseFileDeleteOptions } from '/api/hooks/filesService';

import { removeAssetMutationKey } from './constants';

export const useAssetDelete = (options?: UseFileDeleteOptions) => {
  return useEitherMutation(({ appID, fileID }) => services2.appAssetsService.delete(appID as AppID, fileID), {
    mutationKey: removeAssetMutationKey,
    ...options,
  });
};
