import { CredentialsID } from '@playq/octopus2-auth';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { removeCredentialMutationKeys } from './constants';

export const useDeleteCredentials = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, CredentialsID>
) =>
  useEitherMutation((id: CredentialsID) => services2.adminCredentialsManagementService.delete(id), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: removeCredentialMutationKeys,
    ...options,
  });
