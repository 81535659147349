import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { IssueId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const updateIssuesStatusBatchMutationKey: MutationKey = ['issue', 'mutation', 'updateIssueStatus', 'batch'];

export const useUpdateIssuesStatus = (
  options?: UseMutationOptionsExtended<
    CommonSuccess,
    GenericFailure | Error,
    { ids: IssueId[]; resolved: boolean; env: Environment }
  >
) =>
  useEitherMutation(
    ({ ids, resolved, env }) => services2.audienceSupportService.updateIssueStatusBatch(ids, resolved, env),
    {
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: updateIssuesStatusBatchMutationKey,
      ...options,
    }
  );
