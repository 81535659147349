import { UseMutationOptions } from '@tanstack/react-query';

import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { IInvokeScriptsHook } from '/common/scripts/types';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';

import { appsPackagesService, appsPackagesServiceName } from './constants';
import { IAppsPackagePromoteOptionsVariables, IPromotePackageParams } from './types';

export const useAppsPackagePromoteScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptions<CommonSuccess, GenericFailure | Error, IAppsPackagePromoteOptionsVariables>
) =>
  useEitherMutationScripted<IPromotePackageParams, CommonSuccess, IInvokeScriptsHook>({
    serviceMethod: appsPackagesService.promotePackage.bind(appsPackagesService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appsPackagesServiceName,
      mutationType: 'package promotion',
    },
    options,
  });
