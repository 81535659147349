import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { ClusterizationRunId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
//TODO: create generic
export type DeleteRunsPayloadType = {
  ids: ClusterizationRunId[];
  env: Environment;
};

export const delteRunsKey: MutationKey = ['runs', 'mutation', 'delete'];

export const useDeleteRuns = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, DeleteRunsPayloadType>
) =>
  useEitherMutation(({ ids, env }) => services2.audienceRequestsClusterizationService.deleteRuns(ids, env), {
    onSuccess: (_, variables) => {
      snackbarService.success(`${variables.ids.length > 1 ? 'Runs have' : 'Run has'}  been deleted`);
    },
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: delteRunsKey,
    ...options,
  });
