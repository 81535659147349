import { UseMutationOptions } from '@tanstack/react-query';

import { SupportRequestId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';
import { GenericFailure } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const useAddSupportRequestNote = (
  options?: UseMutationOptions<string, GenericFailure | Error, { id: SupportRequestId; note: string; env: Environment }>
) =>
  useEitherMutation(({ id, note, env }) => services2.audienceSupportService.addSupportRequestsNote(id, note, env), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
