import { useMemo } from 'react';
import { UseQueryOptions, QueryKey } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { CheckFileRequest, CheckFileResponse, FileState } from '@playq/octopus2-files';
import { AppID } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';

import { UseCheckFileType } from '../filesService';

export const checkAssetKeys: QueryKey = ['asset', 'checkFile'];

export const useCheckAsset: UseCheckFileType = (
  { appID, check },
  options?: UseQueryOptions<CheckFileResponse | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => checkAssetKeys.concat(check), [check]);

  const isValid = useMemo(() => check !== undefined && appID !== undefined, [appID, check]);

  const res = useEitherQuery(
    keys,
    () => services2.appAssetsService.checkFile(appID as AppID, check as CheckFileRequest),
    {
      onError: console.error,
      ...options,
      enabled: options?.enabled !== false && isValid,
    }
  );

  return {
    ...res,
    status: res.data?.state ?? FileState.NotFound,
    tags: keys,
  };
};
