import { useCallback } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { PackageResponse, PortalAppPackage } from '@playq/octopus2-economy';
import { PackageID } from '@playq/services-bookkeeper';

import { useEitherQuery } from '/api/service-hooks/';

import { appsPackagesService } from './constants';

export const appsPackageRetrieveKeys: unknown[] = ['apps', 'package', 'retrieve'];

export const useAppsPackageRetrieve = (
  id?: PackageID,
  options?: UseQueryOptions<PackageResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, ...res } = useEitherQuery(
    appsPackageRetrieveKeys.concat(id),
    () => {
      if (id) {
        return appsPackagesService.getPackage(id);
      }
    },
    options
  );

  const mutateUpdatedPackage = useCallback(
    (appPackage: PortalAppPackage) => {
      if (res.data) {
        const packageResponse = new PackageResponse(res.data.serialize());

        packageResponse.pkg = appPackage;

        mutate(packageResponse);
      }
    },
    [mutate, res.data]
  );

  return {
    ...res,
    mutate,
    mutateUpdatedPackage,
  };
};
