import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { ClusterizationTaskId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type StopRunPayloadType = {
  taskId: ClusterizationTaskId;
  env: Environment;
};

export const useStopRunKey: MutationKey = ['stop', 'mutation', 'run'];

export const useStopRun = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, StopRunPayloadType>
) =>
  useEitherMutation(({ taskId, env }) => services2.audienceRequestsClusterizationService.stop(taskId, env), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: useStopRunKey,
    ...options,
  });
