import { MutationKey } from '@tanstack/query-core';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';

import { UseMutationOptionsExtended, useEitherMutation } from '/api/service-hooks';
import { services2 } from '/api/services2';
import { snackbarService } from '/common/snackbarService';

const dropIndexKey: MutationKey = ['attachments', 'index', 'drop'];
export const useDropIndexAttachment = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, { env: Environment }>
) =>
  useEitherMutation(({ env }) => services2.audienceSupportService.dropAttachmentsIndex(env), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: dropIndexKey,
    ...options,
  });
