import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppIssue, IssueId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { handleEntriesMeta } from '/component/Support/BeetlesLookup/helpers';

import {
  addNote,
  removeNote,
  updateNote,
  updateResolved,
} from '../../../component/Support/BeetlesLookup/content/cache';

import { audienceSupportQueryKeys } from './useQuerySupportRequests';

const emptyAppIssue = new AppIssue();

export const useRetrieveIssue = (
  id: IssueId,
  env: Environment = Environment.Live,
  options?: UseQueryOptions<AppIssue | undefined, GenericFailure | Error>
) => {
  const keys = useMemo(() => audienceSupportQueryKeys.concat('retrieve', 'issue', id, env), [id, env]);

  const res = useEitherQuery(keys, () => services2.audienceSupportService.retrieveIssue(id, env), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });

  const cacheOptimization = useMemo(() => {
    const createPrevDataClass = (oldData: AppIssue) => new AppIssue(oldData.serialize());
    return {
      addNote: addNote<AppIssue>(createPrevDataClass, res.mutate),
      removeNote: removeNote<AppIssue>(createPrevDataClass, res.mutate),
      updateNote: updateNote<AppIssue>(createPrevDataClass, res.mutate),
      updateResolved: updateResolved<AppIssue>(createPrevDataClass, res.mutate),
    };
  }, [res.mutate]);
  const metaEntries = useMemo(() => handleEntriesMeta(res.data?.meta), [res.data?.meta]);
  return { ...res, updatedAt: res.dataUpdatedAt, issue: res.data || emptyAppIssue, metaEntries, cacheOptimization };
};
