import { MutationKey } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { RequestStatus, SupportRequestId, AppSupportRequest } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export type UpdateRequestStatusPayload = {
  id: SupportRequestId;
  status: RequestStatus;
  env: Environment;
};

export const updateRequestStatusMutationKey: MutationKey = ['supportRequest', 'mutation', 'updateRequestStatus'];

export const useUpdateRequestStatus = (
  options?: UseMutationOptionsExtended<AppSupportRequest, GenericFailure | Error, UpdateRequestStatusPayload>
) =>
  useEitherMutation(({ id, status, env }) => services2.audienceSupportService.updateRequestStatus(id, status, env), {
    onError: (err) => snackbarService.genericFailure(err),
    mutationKey: updateRequestStatusMutationKey,
    ...options,
  });
