import { UseQueryOptions } from '@tanstack/react-query';

import { Filter, SortDirection } from '@playq/octopus-common';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { AppsSort, QueryAppsResponse, AppsSortField } from '@playq/octopus2-apps';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { iteratorMaxLimit } from '/constants';
import { snackbarService } from '/common/snackbarService';

const appsSortBy = [new AppsSort({ ord: SortDirection.None, field: AppsSortField.ID })];

export const adminCompanyLookupQuery: unknown[] = ['admin', 'company', 'service', 'getCompanyApps'];
export const useAdminCompanyLookupQuery = (
  company: number,
  sortBy: AppsSort[] = appsSortBy,
  iterator: OffsetLimit = new OffsetLimit({ offset: 0, limit: iteratorMaxLimit }),
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptions<QueryAppsResponse | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery(
    adminCompanyLookupQuery.concat(company, iterator, sortBy, filterBy),
    () => {
      return services2.adminCompanyLookupService.getCompanyApps(company, iterator, sortBy, filterBy);
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );
  const entities = res.data instanceof QueryAppsResponse ? res.data.entities : [];
  return { ...res, entities };
};
