import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Filter, Environment, AppID } from '@playq/octopus-common';
import {
  EnvironmentPackages,
  PackagesSort,
  PackagesResponse,
  ItemDetails,
  RegisteredPackages,
  PortalAppPackage,
} from '@playq/octopus2-economy';

import {
  useEitherQuery,
  useQueryPrefetch,
  UseQueryOptionsExtended,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { QueryHelpers } from '/helpers/query';

import { PackagePromotionPayload } from './types';
import { appsPackagesQueryKeys, appsPackagesService } from './constants';

const emptyArray: [] = [];
const defaultItemDetails: Record<string, ItemDetails | undefined> = {};
const defaultEnvs = new EnvironmentPackages({
  live: new RegisteredPackages().serialize(),
  sandbox: new RegisteredPackages().serialize(),
}).serialize();
const emptyFilterBy: {
  [key: string]: Filter;
} = {};

export const useAppsPackagesQuery = (
  iterator: OffsetLimit = QueryHelpers.getMaxIterator(),
  sortBy: PackagesSort[] = emptyArray,
  filterBy: {
    [key: string]: Filter;
  } = emptyFilterBy,
  options?: UseQueryOptionsExtended<PackagesResponse | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID);

  const queryClient = useQueryClient();

  const keys = useMemo(
    () => appsPackagesQueryKeys.concat(appID, iterator, sortBy, filterBy),
    [appID, iterator, sortBy, filterBy]
  );

  const enabled = useMemo(() => (options?.enabled ?? true) && appID !== undefined, [options?.enabled, appID]);
  const enablePrefetch = useMemo(
    () => (options?.enablePrefetch ?? true) && appID !== undefined,
    [options?.enablePrefetch, appID]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    appsPackagesQueryKeys.concat(appID, iterator, sortBy, filterBy),
    () => appsPackagesService.queryPackages(appID as AppID, iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
      enabled,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys: appsPackagesQueryKeys.concat(appID, iterator, sortBy, filterBy),
    enabled: enablePrefetch,
    total,
    args: [appID as AppID, iterator, sortBy, filterBy],
    serviceMethod: appsPackagesService.queryPackages.bind(appsPackagesService),
  });

  const mutatePromotion = useCallback(
    ({ id, env, demote }: PackagePromotionPayload) => {
      mutate((prevResponse) => {
        if (prevResponse) {
          const packagesResponse = new PackagesResponse();
          packagesResponse.all = prevResponse.all;
          packagesResponse.features = prevResponse.features;
          packagesResponse.total = prevResponse.total;
          packagesResponse.iterator = prevResponse.iterator;
          packagesResponse.envs = prevResponse.envs;

          const envProp = env === Environment.Sandbox ? 'sandbox' : 'live';
          if (demote) {
            packagesResponse.envs[envProp].packages = packagesResponse.envs[envProp].packages.filter(
              (packageID) => packageID.serialize() !== id.serialize()
            );
          } else {
            packagesResponse.envs[envProp].packages.push(id);
          }
          return packagesResponse;
        }
      });
    },
    [mutate]
  );

  const mutateDeletedPackage = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (pkg?: PortalAppPackage) => pkg?.content?.id,
    getEntities: (r: PackagesResponse) => r.all,
    setEntities: (r: PackagesResponse, nPackages: PortalAppPackage[]) => {
      r.all = nPackages;
    },
    createNewResponse: (prevResponse: PackagesResponse) => new PackagesResponse(prevResponse.serialize()),
    mutateCustomFields: ({
      response,
      nextResponse,
    }: {
      response: PackagesResponse;
      nextResponse?: PackagesResponse;
    }) => {
      response.features = { ...response.features, ...nextResponse?.features };
    },
  });

  const removeQueries = useCallback(() => {
    queryClient.removeQueries(appsPackagesQueryKeys);
  }, [queryClient]);

  return {
    ...res,
    packages: res.data?.all || emptyArray,
    envs: res.data?.envs.serialize() || defaultEnvs,
    itemsDetails: res.data?.features || defaultItemDetails,
    total: res.data?.total ?? 0,
    tags: keys,
    mutatePromotion,
    mutateDeletedPackage,
    removeQueries,
    refetch,
  };
};
