import { GenericFailure } from '@playq/services-shared';
import { PackageResponse, PortalAppPackage } from '@playq/octopus2-economy';

import { IInvokeScriptsHook } from '/common/scripts/types';
import { UseMutationOptionsExtended } from '/api/service-hooks';

import { useEitherMutationScripted } from '../scriptsService/useEitherMutationScripted';

import { IAppsPackageUpdateOptionsVariables, IUpdatePackageParams } from './types';
import { appsPackagesService, appsPackagesServiceName, updatePackageMutationKey } from './constants';

export const useAppsPackageUpdateScripted = (
  scriptsResolver: IInvokeScriptsHook,
  options?: UseMutationOptionsExtended<
    PortalAppPackage,
    GenericFailure | Error,
    IAppsPackageUpdateOptionsVariables,
    PackageResponse | undefined
  >
) =>
  useEitherMutationScripted<IUpdatePackageParams, PortalAppPackage, IInvokeScriptsHook, PackageResponse | undefined>({
    serviceMethod: appsPackagesService.updatePackage.bind(appsPackagesService),
    scriptsResolver,
    snackbarMessageMetadata: {
      serviceName: appsPackagesServiceName,
      mutationType: 'package update',
    },
    options: {
      mutationKey: updatePackageMutationKey,
      ...options,
    },
  });
