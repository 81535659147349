import { MutationKey, useQueryClient } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { ClusterizationTaskCreateData, ClusterizationTaskId } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

export const taskCreateKeys: MutationKey = ['task', 'muatation', 'create'];

export const useCreateTask = (
  options?: UseMutationOptionsExtended<
    ClusterizationTaskId,
    GenericFailure | Error,
    {
      clusterTask: ClusterizationTaskCreateData;
      env: Environment;
    }
  >
) => {
  const queryClient = useQueryClient();
  return useEitherMutation(
    ({ clusterTask, env }) => services2.audienceRequestsClusterizationService.create(clusterTask, env),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tasks', 'query']);
      },
      onError: (err) => snackbarService.genericFailure(err),
      mutationKey: taskCreateKeys,
      ...options,
    }
  );
};
