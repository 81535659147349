import { useMemo } from 'react';
import { UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { GenericFailure } from '@playq/services-shared';
import { MetaResponse } from '@playq/services-beetle';
import { AppID, Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { useEitherQuery } from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { iteratorMaxLimit } from '/constants';

export const supportRequestsMetaKeys: unknown[] = ['query', 'service', 'audience', 'support', 'supportRequest', 'meta'];

const emptyArray: [] = [];

export const useQuerySupportRequestsMeta = (
  env: Environment = Environment.Live,
  keys = iteratorMaxLimit,
  valuesPerKey = iteratorMaxLimit,
  options?: UseQueryOptions<MetaResponse | undefined, GenericFailure | Error>
) => {
  const appID: AppID | undefined = useSelector(appToolkit.selectors.appID);

  const tags = useMemo(
    () => supportRequestsMetaKeys.concat(appID, env, keys, valuesPerKey),
    [appID, env, keys, valuesPerKey]
  );

  const res = useEitherQuery(
    tags,
    () => {
      if (appID) {
        return services2.audienceSupportService.querySupportRequestsMeta(appID, keys, valuesPerKey, env);
      }
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  return { ...res, keys: res.data?.keys || emptyArray };
};
