import { UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { InventoryPolicy } from '@playq/octopus2-economy';

import { useEitherQuery } from '/api/service-hooks';

import { appsInventoryService } from './constants';

export const appsInventoryPolicyKeys = ['apps', 'inventory', 'policy'];

const emptyArray: [] = [];

export const useAppsInventoryPolicy = (
  options?: UseQueryOptions<InventoryPolicy | undefined, GenericFailure | Error>
) => {
  const res = useEitherQuery(appsInventoryPolicyKeys, () => appsInventoryService.policy(), options);
  const data = res.data instanceof InventoryPolicy ? res.data : new InventoryPolicy();
  const classes = res.data?.classes ?? emptyArray;

  return { ...res, data, classes, keys: appsInventoryPolicyKeys };
};
