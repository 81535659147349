import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { CredentialsSort, QueryCredentialsResponse, CredentialsBrief } from '@playq/octopus2-auth';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Filter } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import {
  useEitherQuery,
  useQueryPrefetch,
  UseQueryOptionsExtended,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';

import { queryAdminCredentialsKeys } from './constants';

const emptyArray: [] = [];
const emptyFilterBy: {
  [key: string]: Filter;
} = {};

export const useQueryAdminCredentials = (
  iterator: OffsetLimit,
  sortBy: CredentialsSort[] = emptyArray,
  filterBy: {
    [key: string]: Filter;
  } = emptyFilterBy,
  options?: UseQueryOptionsExtended<QueryCredentialsResponse | undefined, GenericFailure | Error>
) => {
  const queryClient = useQueryClient();

  const keys = useMemo(
    () => queryAdminCredentialsKeys.concat(iterator, sortBy, filterBy),
    [iterator, sortBy, filterBy]
  );

  const { mutate, refetch, ...res } = useEitherQuery(
    keys,
    () => services2.adminCredentialsManagementService.queryCredentials(iterator, sortBy, filterBy),
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: options?.enablePrefetch !== false,
    total,
    args: [iterator, sortBy, filterBy],
    serviceMethod: services2.adminCredentialsManagementService.queryCredentials.bind(
      services2.adminCredentialsManagementService
    ),
  });

  const mutateAfterDeletingCredentials = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID: (e?: CredentialsBrief) => e?.id,
    getEntities: (r: QueryCredentialsResponse) => r.entities,
    setEntities: (r: QueryCredentialsResponse, newEntities: CredentialsBrief[]) => {
      r.entities = newEntities;
    },
    createNewResponse: (prevResponse: QueryCredentialsResponse) =>
      new QueryCredentialsResponse(prevResponse.serialize()),
  });

  return {
    ...res,
    credentials: res.data?.entities || emptyArray,
    total,
    tags: keys,
    refetch,
    mutateAfterDeletingCredentials,
  };
};
